import * as Sentry from '@sentry/react'

import { store } from '@/utilities/store'
import NewOrderSound from '@/assets/audio/new-order.mp3'
import { DisplayOrderContract, LocationContract, OrderOrigin } from '@/types/api'

const newOrderSound = new Audio(NewOrderSound)

export const getWindowSizes = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  }
}

export const playNewOrderSound = async () => {
  try {
    await newOrderSound.play()
  } catch (error) {
    console.error(error)
  }
}

export const logout = () => {
  store.dispatch.authentication.resetState()
  store.dispatch.locations.resetState()
  store.dispatch.orders.resetState()

  setSentryUser(null)
}

export const getFilteredOrders = (location?: LocationContract, orders?: DisplayOrderContract[]) => {
  if (!location || !orders) {
    return []
  }

  return orders.filter((order) => {
    switch (order.origin) {
      case OrderOrigin.Bolt:
        return !!location.showBoltOrders
      case OrderOrigin.Wolt:
        return !!location.showWoltOrders
      case OrderOrigin.Qr:
        return !!location.showQrOrders
      case OrderOrigin.SelfService:
        return !!location.showSelfServiceOrders
      default:
        return true
    }
  })
}

export const minutesToMs = (minutes: number) => {
  return 1000 * 60 * minutes
}

export const getPrinterImgCtx = async (src?: string, height: number = 128) => {
  if (!src) {
    return
  }

  const canvas = document.createElement('canvas')

  const ctx = canvas.getContext('2d')
  if (!ctx) {
    return
  }

  const img = new Image()
  img.src = src
  img.crossOrigin = 'anonymous'

  try {
    await new Promise<void>((resolve, reject) => {
      img.onload = () => {
        resolve()
      }

      img.onerror = reject
    })

    const width = Math.round(height * (img.naturalWidth / img.naturalHeight))

    canvas.width = width
    canvas.height = height

    img.width = width
    img.height = height

    ctx.drawImage(img, 0, 0, width, height)

    return ctx
  } catch (error) {
    console.error(error)
  } finally {
    img.remove()
    canvas.remove()
  }
}

export const setSentryUser = (user: Sentry.User | null) => {
  Sentry.setUser(user)
}

export const captureSentryException = (error: unknown) => {
  Sentry.captureException(error)
}

export const waitForMS = (ms: number) => {
  return new Promise<void>((resolve) => {
    const timeout = setTimeout(() => {
      resolve()

      clearTimeout(timeout)
    }, ms)
  })
}
