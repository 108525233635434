import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { isAxiosError } from 'axios'
import { useParams } from 'react-router-dom'

import { Dispatch, RootState } from '@/utilities/store'
import { HEADER_HEIGHT } from '@/components/Header'
import { WindowSizes, useOrdersFetch, useWindowSizes } from '@/utilities/hooks'
import { MarkPaidDialog, ReceiptDialog, Spinner } from '@/components'
import Order from './components/Order'
import { ErrorContract, KitchenStatus, OrderStatus, PaymentStatus } from '@/types/api'
import { selectLocation } from '@/models/locations'
import { selectFilteredKitchenOrders, selectKitchenOrdersObj } from '@/models/orders'
import AddBaseOrderButton, { ADD_BASE_ORDER_BUTTON_SIZE } from '@/components/AddBaseOrderButton'

interface ScreenContainerProps {
  sizes: WindowSizes
}

const ScreenContainer = styled.div<ScreenContainerProps>`
  height: ${({ sizes }) => sizes.height - HEADER_HEIGHT - 1}px;
  position: relative;
`

const ContentContainer = styled.div`
  background-color: #bfbfbf;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 0.0625rem;
  height: 100%;
`

const OrdersContainer = styled.div`
  background-color: #ffffff;
  overflow-y: auto;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-content: start;
  gap: 1rem;
`

const OrdersLeftContainer = styled(OrdersContainer)`
  padding-bottom: calc(1rem + ${ADD_BASE_ORDER_BUTTON_SIZE}px + 1rem);
`

const SpinnerContainer = styled(ScreenContainer)`
  padding: 1rem;
  display: grid;
  place-items: center;
`

const AddBaseOrderButtonContainer = styled.div`
  position: absolute;
  left: 1rem;
  bottom: 1rem;
`

const Kitchen = () => {
  const [receiptDialogOpen, setReceiptDialogOpen] = useState<boolean>(false)
  const [markPaidDialogOpen, setMarkPaidDialogOpen] = useState<boolean>(false)
  const [selectedOrderId, setSelectedOrderId] = useState<string>('')

  const { locationId } = useParams<{ locationId: string }>()

  const location = useSelector((state: RootState) => selectLocation(state, locationId))
  const kitchenOrders = useSelector((state: RootState) => selectFilteredKitchenOrders(state, locationId))
  const kitchenOrdersObj = useSelector(selectKitchenOrdersObj)

  const markKitchenReadyLoading = useSelector((state: RootState) => state.loading.effects.orders.markKitchenReady)
  const markKitchenOrderReadyLoading = useSelector(
    (state: RootState) => state.loading.effects.orders.markKitchenOrderReady,
  )
  const markKitchenOrderPaidLoading = useSelector(
    (state: RootState) => state.loading.effects.orders.markKitchenOrderPaid,
  )
  const markKitchenOrderFinishedLoading = useSelector(
    (state: RootState) => state.loading.effects.orders.markKitchenOrderFinished,
  )
  const sendInvoiceLoading = useSelector((state: RootState) => state.loading.effects.orders.sendInvoice)

  const dispatch = useDispatch<Dispatch>()

  const sizes = useWindowSizes()

  const { initLoading } = useOrdersFetch({ locationId, playKitchenOrdersSound: true })

  const loading = useMemo(() => {
    return (
      markKitchenReadyLoading ||
      markKitchenOrderPaidLoading ||
      markKitchenOrderFinishedLoading ||
      sendInvoiceLoading ||
      markKitchenOrderReadyLoading
    )
  }, [
    markKitchenOrderFinishedLoading,
    markKitchenOrderPaidLoading,
    markKitchenOrderReadyLoading,
    markKitchenReadyLoading,
    sendInvoiceLoading,
  ])

  const toggleReceiptDialog = () => {
    setReceiptDialogOpen((prevReceiptDialogOpen) => !prevReceiptDialogOpen)
  }

  const toggleMarkPaidDialog = () => {
    setMarkPaidDialogOpen((prevMarkPaidDialogOpen) => !prevMarkPaidDialogOpen)
  }

  const handleOrderClick = (orderId: string) => {
    const kitchenOrder = kitchenOrdersObj[orderId]
    if (!kitchenOrder) {
      return
    }

    if (kitchenOrder.paymentStatus === PaymentStatus.Unpaid) {
      handleMarkKitchenOrderPaid(orderId)
      return
    }

    if (kitchenOrder.kitchenStatus === KitchenStatus.Created) {
      handleMarkKitchenReady(orderId)
      return
    }

    if (kitchenOrder.status === OrderStatus.Created) {
      handleMarkKitchenOrderReady(orderId)
      return
    }

    handleMarkKitchenOrderFinished(orderId)
  }

  const handleMarkKitchenOrderReady = async (orderId: string) => {
    try {
      await dispatch.orders.markKitchenOrderReady(orderId)
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      alert(error.response?.data.message)
    }
  }

  const handleMarkKitchenReady = async (orderId: string) => {
    try {
      await dispatch.orders.markKitchenReady(orderId)
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      alert(error.response?.data.message)
    }
  }

  const handleMarkKitchenOrderFinished = async (orderId: string) => {
    try {
      await dispatch.orders.markKitchenOrderFinished(orderId)
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      alert(error.response?.data.message)
    }
  }

  const handleMarkKitchenOrderPaid = (orderId: string) => {
    setSelectedOrderId(orderId)

    if (location?.enableCashOrdersConfirmation) {
      toggleMarkPaidDialog()
    } else {
      handleMarkPaidDialogConfirm(orderId)
    }
  }

  const handleMarkPaidDialogConfirm = async (orderId?: string) => {
    try {
      await dispatch.orders.markKitchenOrderPaid(orderId || selectedOrderId)

      setMarkPaidDialogOpen(false)
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      alert(error.response?.data.message)
    }
  }

  const handleReceiptDialogDone = async (email: string) => {
    try {
      await dispatch.orders.sendInvoice({ orderId: selectedOrderId, email })

      toggleReceiptDialog()
    } catch (error) {
      console.error(error)

      if (!isAxiosError<ErrorContract>(error)) {
        return
      }

      alert(error.response?.data.message)
    }
  }

  const handleOrderReceiptClick = (orderId: string) => {
    setSelectedOrderId(orderId)

    toggleReceiptDialog()
  }

  if (initLoading) {
    return (
      <SpinnerContainer sizes={sizes}>
        <Spinner />
      </SpinnerContainer>
    )
  }

  return (
    <>
      <ScreenContainer sizes={sizes}>
        <ContentContainer>
          <OrdersLeftContainer>
            {kitchenOrders.created.map((order) => (
              <div key={order.id}>
                <Order
                  order={order}
                  disabled={loading}
                  createdOrderBackgroundColor={location?.createdOrderBackgroundColor!}
                  enableEmailButton={location?.enableEmailButton}
                  onOrderClick={handleOrderClick}
                  onOrderReceiptClick={handleOrderReceiptClick}
                />
              </div>
            ))}
          </OrdersLeftContainer>

          <OrdersContainer>
            {kitchenOrders.ready.map((order) => (
              <div key={order.id}>
                <Order
                  order={order}
                  disabled={loading}
                  readyOrderBackgroundColor={location?.readyOrderBackgroundColor!}
                  enableEmailButton={location?.enableEmailButton}
                  onOrderClick={handleOrderClick}
                  onOrderReceiptClick={handleOrderReceiptClick}
                />
              </div>
            ))}
          </OrdersContainer>
        </ContentContainer>

        <AddBaseOrderButtonContainer>
          <AddBaseOrderButton locationId={locationId} />
        </AddBaseOrderButtonContainer>
      </ScreenContainer>

      <ReceiptDialog
        open={receiptDialogOpen}
        disabled={sendInvoiceLoading}
        onDone={handleReceiptDialogDone}
        onClose={toggleReceiptDialog}
      />

      <MarkPaidDialog
        open={markPaidDialogOpen}
        disabled={markKitchenOrderPaidLoading}
        onConfirm={handleMarkPaidDialogConfirm}
        onClose={toggleMarkPaidDialog}
      />
    </>
  )
}

export default Kitchen
