import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'

import { LocationContract } from '@/types/api'

interface LocationsSelectProps {
  locations?: LocationContract[]
  selectedLocationId?: string
  onLocationSelect?: (locationId: string) => void
}

const LocationsSelect = ({ locations = [], selectedLocationId, onLocationSelect }: LocationsSelectProps) => {
  const handleChange = (e: SelectChangeEvent) => {
    onLocationSelect?.(e.target.value)
  }

  return (
    <Box sx={{ minWidth: '12.5rem' }}>
      <FormControl fullWidth size="small">
        <InputLabel>Location</InputLabel>

        <Select value={selectedLocationId} label="Location" onChange={handleChange}>
          {locations.map((location) => (
            <MenuItem key={location.id} value={location.id}>
              {location.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default LocationsSelect
