import { createModel } from '@rematch/core'
import { AxiosResponse } from 'axios'
import { createSelector } from '@rematch/select'
import { matchPath } from 'react-router-dom'

import { RootModel } from '@/models'
import { api } from '@/utilities/api'
import {
  CreateBaseOrderContract,
  DisplayOrderContract,
  KitchenStatus,
  OrderOrigin,
  OrderStatus,
  PaymentStatus,
  PrintStatus,
  SendOrderInvoiceContract,
} from '@/types/api'
import { RootState } from '@/utilities/store'
import { selectLocation, selectLocationsObj } from './locations'
import { getFilteredOrders } from '@/utilities/functions'

interface OrdersState {
  orders: DisplayOrderContract[]
  kitchenOrders: DisplayOrderContract[]
}

const initialState: OrdersState = {
  orders: [],
  kitchenOrders: [],
}

export const orders = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setOrders(state, orders: DisplayOrderContract[]) {
      return { ...state, orders }
    },
    addOrder(state, order: DisplayOrderContract) {
      return { ...state, orders: [...state.orders, order] }
    },
    setKitchenOrders(state, kitchenOrders: DisplayOrderContract[]) {
      return { ...state, kitchenOrders }
    },
    addKitchenOrder(state, kitchenOrder: DisplayOrderContract) {
      return { ...state, kitchenOrders: [...state.kitchenOrders, kitchenOrder] }
    },
    updateOrder(state, { id, data }: { id: string; data: DisplayOrderContract }) {
      return {
        ...state,
        orders: state.orders.map((order) => (order.id === id ? { ...order, ...data } : order)),
      }
    },
    updateKitchenOrder(state, { id, data }: { id: string; data: DisplayOrderContract }) {
      return {
        ...state,
        kitchenOrders: state.kitchenOrders.map((kitchenOrder) =>
          kitchenOrder.id === id ? { ...kitchenOrder, ...data } : kitchenOrder,
        ),
      }
    },
    resetState() {
      return initialState
    },
  },
  effects: (dispatch) => ({
    async getOrders(locationId: string) {
      const { data }: AxiosResponse<DisplayOrderContract[]> = await api.get(`/orders/active/${locationId}`)

      dispatch.orders.setOrders(data)

      return data
    },
    async getKitchenOrders(locationId: string) {
      const { data }: AxiosResponse<DisplayOrderContract[]> = await api.get(`/orders/kitchen/${locationId}`)

      dispatch.orders.setKitchenOrders(data)

      return data
    },
    async markOrderReady(orderId: string) {
      const res: AxiosResponse<DisplayOrderContract> = await api.post(`/orders/${orderId}/markReady`)

      dispatch.orders.updateOrder({ id: orderId, data: res.data })
    },
    async markKitchenOrderReady(orderId: string) {
      const res: AxiosResponse<DisplayOrderContract> = await api.post(`/orders/${orderId}/markReady`)

      dispatch.orders.updateKitchenOrder({ id: orderId, data: res.data })
    },
    async markOrderPaid(orderId: string) {
      await api.post(`/orders/${orderId}/markPaid`)

      dispatch.orders.updateOrder({ id: orderId, data: { paymentStatus: PaymentStatus.Paid } })
    },
    async markKitchenOrderPaid(orderId: string) {
      await api.post(`/orders/${orderId}/markPaid`)

      dispatch.orders.updateKitchenOrder({ id: orderId, data: { paymentStatus: PaymentStatus.Paid } })
    },
    async markOrderFinished(orderId: string) {
      const res: AxiosResponse<DisplayOrderContract> = await api.post(`/orders/${orderId}/markFinished`)

      dispatch.orders.updateOrder({ id: orderId, data: res.data })
    },
    async markKitchenOrderFinished(orderId: string) {
      const res: AxiosResponse<DisplayOrderContract> = await api.post(`/orders/${orderId}/markFinished`)

      dispatch.orders.updateKitchenOrder({ id: orderId, data: res.data })
    },
    async markKitchenReady(orderId: string) {
      await api.post(`/orders/${orderId}/kitchen/ready`)

      dispatch.orders.updateKitchenOrder({ id: orderId, data: { kitchenStatus: KitchenStatus.Ready } })
    },
    async markKitchenOrderPrinted(orderId: string) {
      await api.post(`/orders/${orderId}/markPrinted`)

      dispatch.orders.updateKitchenOrder({ id: orderId, data: { printStatus: PrintStatus.Printed } })
    },
    async createBaseOrder(payload: CreateBaseOrderContract) {
      const { data }: AxiosResponse<DisplayOrderContract> = await api.post('/orders/createBase', payload)

      dispatch.orders.addOrder(data)

      dispatch.orders.addKitchenOrder(data)
    },
    async sendInvoice(payload: SendOrderInvoiceContract) {
      await api.post('/orders/sendInvoice', payload)
    },
  }),
})

export const selectOrdersObj = createSelector(
  ({ orders }: RootState) => orders,
  ({ orders }) => {
    return orders.reduce<{ [orderId: string]: DisplayOrderContract }>((obj, order) => {
      obj[order.id!] = order

      return obj
    }, {})
  },
)

export const selectKitchenOrdersObj = createSelector(
  ({ orders }: RootState) => orders,
  ({ kitchenOrders }) => {
    return kitchenOrders.reduce<{ [orderId: string]: DisplayOrderContract }>((obj, kitchenOrder) => {
      obj[kitchenOrder.id!] = kitchenOrder

      return obj
    }, {})
  },
)

export const selectOrders = createSelector(
  ({ orders }: RootState) => orders,
  ({ orders }) => {
    return orders.reduce<{ created: DisplayOrderContract[]; ready: DisplayOrderContract[] }>(
      (obj, order) => {
        switch (order.status) {
          case OrderStatus.Created:
            obj.created.push(order)

            break
          case OrderStatus.Ready:
            obj.ready.push(order)

            break
          default:
            break
        }

        return obj
      },
      { created: [], ready: [] },
    )
  },
)

export const selectFilteredOrders = createSelector(
  ({ orders }: RootState) => orders,
  selectLocation,
  selectOrders,
  (_, location, orders) => {
    return {
      created: getFilteredOrders(location, orders.created),
      ready: getFilteredOrders(location, orders.ready),
    }
  },
)

export const selectKitchenOrders = createSelector(
  ({ orders }: RootState) => orders,
  ({ kitchenOrders }) => {
    return kitchenOrders.reduce<{ created: DisplayOrderContract[]; ready: DisplayOrderContract[] }>(
      (obj, kitchenOrder) => {
        switch (kitchenOrder.status) {
          case OrderStatus.Created:
            obj.created.push(kitchenOrder)

            break
          case OrderStatus.Ready:
            obj.ready.push(kitchenOrder)

            break
          default:
            break
        }

        return obj
      },
      { created: [], ready: [] },
    )
  },
)

export const selectFilteredKitchenOrders = createSelector(
  ({ orders }: RootState) => orders,
  selectLocation,
  selectKitchenOrders,
  (_, location, kitchenOrders) => {
    return {
      created: getFilteredOrders(location, kitchenOrders.created),
      ready: getFilteredOrders(location, kitchenOrders.ready),
    }
  },
)

export const selectOrdersToPrint = createSelector(
  ({ orders }: RootState) => orders,
  selectKitchenOrders,
  selectLocationsObj,
  (_, kitchenOrders, locationsObj) => {
    const locationId = matchPath({ path: '/:locationId', end: false }, window.location.pathname)?.params?.locationId
    if (!locationId) {
      return
    }

    const location = locationsObj[locationId]
    if (!location) {
      return
    }

    const toPrint = kitchenOrders.created.reduce<DisplayOrderContract[]>((ordersToPrint, kitchenOrder) => {
      const orderId = kitchenOrder.id

      if (!orderId || kitchenOrder.printStatus === PrintStatus.Printed) {
        return ordersToPrint
      }

      switch (kitchenOrder.origin) {
        case OrderOrigin.Bolt:
          if (location.printBoltOrders) {
            ordersToPrint.push(kitchenOrder)
          }
          break
        case OrderOrigin.Qr:
          if (location.printQrOrders) {
            ordersToPrint.push(kitchenOrder)
          }
          break
        case OrderOrigin.SelfService:
          if (location.printSelfServiceOrders) {
            ordersToPrint.push(kitchenOrder)
          }
          break
        case OrderOrigin.Web:
          if (location.printWebOrders) {
            ordersToPrint.push(kitchenOrder)
          }
          break
        case OrderOrigin.Wolt:
          if (location.printWoltOrders) {
            ordersToPrint.push(kitchenOrder)
          }
          break
        // These orders must not be printed
        case OrderOrigin.Deliverect:
        case OrderOrigin.OrderProcessor:
          break
        default:
          break
      }

      return ordersToPrint
    }, [])

    return {
      orders: toPrint,
      printOrderType: !!location.printOrderType,
      receiptLogoImageName:
        location.enableReceiptLogoPrinting && location.receiptLogoImageName ? location.receiptLogoImageName : null,
    }
  },
)
