/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface OrderProductOptionContract {
  /** @format uuid */
  id?: string
  title?: string | null
  /** @format int32 */
  count?: number
}

export interface OrderProductContract {
  /** @format uuid */
  id?: string
  title?: string | null
  /** @format int32 */
  count?: number
  /** @format uuid */
  categoryId?: string | null
  options?: OrderProductOptionContract[] | null
}

export enum OrderType {
  Table = 'Table',
  TakeAway = 'TakeAway',
}

export enum PaymentStatus {
  Paid = 'Paid',
  Unpaid = 'Unpaid',
}

export enum SelfServiceOrderOrigin {
  SelfService = 'SelfService',
  Wolt = 'Wolt',
  Deliverect = 'Deliverect',
  Qr = 'Qr',
  Bolt = 'Bolt',
  Web = 'Web',
}

export interface CreateOrderContract {
  /** @format uuid */
  externalId?: string | null
  name?: string | null
  /** @format uuid */
  locationId?: string
  /** @format int32 */
  number?: number
  orderProducts?: OrderProductContract[] | null
  commentToKitchen?: string | null
  type?: OrderType
  table?: string | null
  paymentStatus?: PaymentStatus
  origin?: SelfServiceOrderOrigin
  guestIdentification?: string | null
  userPhoneNumber?: string | null
}

export enum OrderStatus {
  Created = 'Created',
  Ready = 'Ready',
  Finished = 'Finished',
}

export interface TvOrderContract {
  /** @format uuid */
  id?: string
  /** @format int32 */
  number?: number
  status?: OrderStatus
  readyOrderBackgroundColor?: string | null
  createdOrderBackgroundColor?: string | null
}

export interface ValidationErrorContract {
  fieldName: string
  errorMessage: string
}

export interface ErrorContract {
  /** @format int32 */
  errorCode: number
  message: string
  /** @format int32 */
  internalErrorCode?: number
  validationErrors?: ValidationErrorContract[] | null
}

export interface TvProfileContract {
  createdSectionTitle?: string | null
  createdOrderBackgroundColor?: string | null
  createdOrderTextColor?: string | null
  readySectionTitle?: string | null
  readyOrderBackgroundColor?: string | null
  readyOrderTextColor?: string | null
  createdSectionBackgroudColor?: string | null
  readySectionBackgroudColor?: string | null
  footerImageName?: string | null
  footerBackgroudColor?: string | null
}

export interface FetchOrdersContract {
  /** @format uuid */
  locationId?: string
  /** @format double */
  dateFrom?: number | null
  /** @format double */
  dateTo?: number | null
}

export interface OrderPreparationDetailsContract {
  /** @format uuid */
  id?: string
  /** @format uuid */
  externalId?: string
  /** @format double */
  createdAt?: number
  /** @format double */
  readyDate?: number | null
  /** @format double */
  kitchenReadyDate?: number | null
}

export enum KitchenStatus {
  Created = 'Created',
  Ready = 'Ready',
}

export enum OrderOrigin {
  OrderProcessor = 'OrderProcessor',
  SelfService = 'SelfService',
  Wolt = 'Wolt',
  Deliverect = 'Deliverect',
  Qr = 'Qr',
  Bolt = 'Bolt',
  Web = 'Web',
}

export enum PrintStatus {
  Created = 'Created',
  Printed = 'Printed',
}

export interface DisplayOrderContract {
  /** @format uuid */
  id?: string
  name?: string | null
  /** @format int32 */
  number?: number
  status?: OrderStatus
  readyOrderBackgroundColor?: string | null
  createdOrderBackgroundColor?: string | null
  orderProducts?: OrderProductContract[] | null
  commentToKitchen?: string | null
  type?: OrderType
  kitchenStatus?: KitchenStatus
  table?: string | null
  paymentStatus?: PaymentStatus
  origin?: OrderOrigin
  guestIdentification?: string | null
  printStatus?: PrintStatus
}

export interface LocationContract {
  /** @format uuid */
  id?: string
  title?: string | null
  /** @format int32 */
  readyOrdersExpirationMinutes?: number
  /** @format int32 */
  timeZoneOffset?: number
  readyOrderBackgroundColor?: string | null
  createdOrderBackgroundColor?: string | null
  enableFinishOrderButton?: boolean
  printSelfServiceOrders?: boolean
  printWebOrders?: boolean
  printQrOrders?: boolean
  printWoltOrders?: boolean
  printBoltOrders?: boolean
  showBoltOrders?: boolean
  showWoltOrders?: boolean
  showQrOrders?: boolean
  showSelfServiceOrders?: boolean
  printOrderType?: boolean
  enableEmailButton?: boolean
  enableCashOrdersConfirmation?: boolean
  /** @format int32 */
  createdOrdersExpirationMinutes?: number | null
  enableReceiptLogoPrinting?: boolean
  receiptLogoImageName?: string | null
}

export interface UpdateLocationContract {
  title?: string | null
  /** @format int32 */
  readyOrdersExpirationMinutes?: number
  /** @format int32 */
  timeZoneOffset?: number
  readyOrderBackgroundColor?: string | null
  createdOrderBackgroundColor?: string | null
  enableFinishOrderButton?: boolean
  printSelfServiceOrders?: boolean
  printWoltOrders?: boolean
  printQrOrders?: boolean
  printBoltOrders?: boolean
  printWebOrders?: boolean
  showBoltOrders?: boolean
  showWoltOrders?: boolean
  showQrOrders?: boolean
  showSelfServiceOrders?: boolean
  printOrderType?: boolean
  enableEmailButton?: boolean
  /** @format int32 */
  createdOrdersExpirationMinutes?: number | null
}

export interface CreateBaseOrderContract {
  /** @format int32 */
  number?: number
  /** @format uuid */
  locationId?: string
}

export interface SendOrderInvoiceContract {
  /** @format uuid */
  orderId?: string
  email?: string | null
}

export interface UpdateOrderContract {
  userPhoneNumber?: string | null
}

export interface PrinterContract {
  /** @format uuid */
  id?: string
  serialNumber?: string | null
  name?: string | null
  receiptMarkdown?: string | null
  locations?: string[] | null
  printItems?: string[] | null
}

export interface CreatePrinterContract {
  serialNumber?: string | null
  name?: string | null
  receiptMarkdown?: string | null
  locations?: string[] | null
  printItems?: string[] | null
}

export interface UpdatePrinterContract {
  name?: string | null
  receiptMarkdown?: string | null
  locations?: string[] | null
  printItems?: string[] | null
}

export interface UpdatePrinterStatusContract {
  isPrinterOnline?: boolean
  isPaperOut?: boolean
  isPaperLow?: boolean
  isCoverOpen?: boolean
}

export interface PrintOrderContract {
  /** @format uuid */
  id?: string
  receipt?: string | null
}

export interface PrintItemContract {
  /** @format uuid */
  id?: string
  title?: string | null
}

export interface CredentialsContract {
  email?: string | null
  password?: string | null
}

export interface UserProfileContract {
  /** @format uuid */
  id?: string
  email?: string | null
  description?: string | null
  isAdmin?: boolean
  locations?: LocationContract[] | null
}

export interface UserAuthenticationContract {
  token?: string | null
  user?: UserProfileContract
}
