import { createModel } from '@rematch/core'

import { RootModel } from '@/models'

interface AppState {
  printerIpAddress: string
  soundEnabled: boolean
  printing: boolean
  printerHasError: boolean
}

const initialState: AppState = {
  printerIpAddress: '',
  soundEnabled: false,
  printing: false,
  printerHasError: false,
}

export const app = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setPrinterIpAddress(state, printerIpAddress: string) {
      return { ...state, printerIpAddress }
    },
    setSoundEnabled(state, soundEnabled: boolean) {
      return { ...state, soundEnabled }
    },
    setPrinting(state, printing: boolean) {
      return { ...state, printing }
    },
    setPrinterHasError(state, printerHasError: boolean) {
      return { ...state, printerHasError }
    },
  },
})
