import { useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { Sidebar } from '@/components'
import { useWindowSizes, WindowSizes } from '@/utilities/hooks'
import Header, { HEADER_HEIGHT } from '@/components/Header'
import { RootState } from '@/utilities/store'

const TRANSITION_TIME = 500 // ms

interface PageContainerProps {
  sizes: WindowSizes
}

interface HeaderContainerProps {
  sizes: WindowSizes
}

interface SidebarContainerProps {
  sizes: WindowSizes
  menuOpen: boolean
}

interface ContentContainerProps {
  menuOpen: boolean
}

const PageContainer = styled.div<PageContainerProps>`
  width: ${({ sizes }) => sizes.width}px;
`

const HeaderContainer = styled.div<HeaderContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: ${({ sizes }) => sizes.width}px;
  border-bottom: 1px solid #bfbfbf;
  padding: 0 1rem;
  background-color: #ffffff;
`

const ContentContainer = styled.div<ContentContainerProps>`
  padding-top: ${HEADER_HEIGHT + 1}px;
  padding-left: ${({ menuOpen }) => (menuOpen ? 16 : 0)}rem;
  transition: padding-left ${TRANSITION_TIME}ms;
`

const SidebarContainer = styled.div<SidebarContainerProps>`
  width: ${({ menuOpen }) => (menuOpen ? 16 : 0)}rem;
  overflow-y: auto;
  height: ${({ sizes }) => sizes.height - HEADER_HEIGHT - 1}px;
  transition: width ${TRANSITION_TIME}ms;
  border-right: 1px solid #bfbfbf;
  position: fixed;
  top: ${HEADER_HEIGHT + 1}px;
  left: 0;
  background-color: #ffffff;
`

const PageLayout = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  const token = useSelector((state: RootState) => state.authentication.token)
  const locations = useSelector((state: RootState) => state.locations.locations)

  const sizes = useWindowSizes()

  const location = useLocation()

  const toggleMenuOpen = () => {
    setMenuOpen((prevMenuOpen) => !prevMenuOpen)
  }

  if (token && location.pathname === '/' && locations.length) {
    const locationId = locations[0].id!

    return <Navigate to={`/${locationId}/orders`} replace />
  }

  return (
    <PageContainer sizes={sizes}>
      <HeaderContainer sizes={sizes}>
        <Header menuOpen={menuOpen} onMenuClick={toggleMenuOpen} />
      </HeaderContainer>

      <ContentContainer menuOpen={menuOpen}>
        {!!token && (
          <SidebarContainer sizes={sizes} menuOpen={menuOpen}>
            <Sidebar />
          </SidebarContainer>
        )}

        <Outlet />
      </ContentContainer>
    </PageContainer>
  )
}

export default PageLayout
